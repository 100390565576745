@imort variables {}



.link-boxes {
    max-width: 1240px;
    text-align: center;
    margin: 0 auto;

    .link-box {
        width: 280px;
        min-height: 180px;
        background-color: #fff;
        border-radius: 0 4px 4px 4px;
        border: 2px solid transparent;
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
        margin-bottom: 30px;
        text-align: left;

        @media screen and ( max-width:  620px) {
            width: 80%;
            margin-left: 0!important; }


        &:hover {
            box-shadow: 0 2px 16px 0 rgba(21,28,46,0.37); }

        .box-head {
            position: relative;
            padding-left: 58px;
            padding-right: 10px;

            span:nth-child(2) {
                display: block;
                font-weight: 500;
                font-size: 20px;
                padding-top: 10.5px;
                padding-bottom: 10.5px;
                border-bottom: 2px solid#C3C9D0; }

            .box-img {
                position: absolute;
                left: -2px;
                top: -2px;
                width: 50px;
                height: 50px;
                border-radius: 0 0 9px 0;
                display: inline-block;

                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-repeat: no-repeat; } } }

        .box-links {
            margin-top: 20px;
            padding: 10px;
            padding-left: 30px;

            li {
                position: relative;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0; }

                a {
                    font-size: 16px;
                    color: #000;
                    font-weight: 400; }

                &:before {
                    content: '';
                    position: absolute;
                    right: calc(100% + 7px);
                    height: 0;
                    width: 0;
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    display: block;
                    top: calc( 50% -1px);
                    transform: translateY(-50%); } } } }


    .box-ciselnik {
        &:hover {
            border: 2px solid #587CBC; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #587CBC!important; }

        .box-links li:before {
            border-left: 4px solid #587CBC; }

        .box-img {
            background-color: #587CBC;

            &:before {
                width: 25px;
                height: 22px;
                background: url('../img/icon_ciselnik@3x.svg'); } } }


    .box-merchadising {
        &:hover {
            border: 2px solid #F6577C; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #F6577C!important; }

        .box-links li:before {
            border-left: 4px solid #F6577C; }

        .box-img {
            background-color: #F6577C;

            &:before {
                width: 28px;
                height: 28px;
                background: url('../img/icon_merch@3x.svg'); } } }

    .box-pohled {
        &:hover {
            border: 2px solid #64D49F; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #64D49F!important; }

        .box-links li:before {
            border-left: 4px solid #64D49F; }

        .box-img {
            background-color: #64D49F;

            &:before {
                width: 28px;
                height: 28px;
                background: url('../img/icon_pohled@3x.svg'); } } }

    .box-dokumenty {
        &:hover {
            border: 2px solid #E79968; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #E79968!important; }

        .box-links li:before {
            border-left: 4px solid #E79968; }

        .box-img {
            background-color: #E79968;

            &:before {
                width: 20px;
                height: 28px;
                background: url('../img/icon_dokumenty@3x.svg'); } } }

    .box-archiv {
        &:hover {
            border: 2px solid #747881; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #747881!important; }

        .box-links li:before {
            border-left: 4px solid #747881; }

        .box-img {
            background-color: #747881;

            &:before {
                width: 29px;
                height: 24px;
                background: url('../img/icon_archiv@3x.svg'); } } }

    .box-vykazy {
        &:hover {
            border: 2px solid #75C8EA; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #75C8EA!important; }

        .box-links li:before {
            border-left: 4px solid #75C8EA; }

        .box-img {
            background-color: #75C8EA;

            &:before {
                width: 29px;
                height: 26px;
                background: url('../img/icon_vykazy@3x.svg'); } } }

    .box-home {
        &:hover {
            border: 2px solid #B584B3; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #B584B3!important; }

        .box-links li:before {
            border-left: 4px solid #B584B3; }

        .box-img {
            background-color: #B584B3;

            &:before {
                width: 28.5px;
                height: 28.5px;
                background: url('../img/icon_dum-region@3x.svg'); } } }

    .box-listing {
        &:hover {
            border: 2px solid #AAC774; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #AAC774!important; }

        .box-links li:before {
            border-left: 4px solid #AAC774; }

        .box-img {
            background-color: #AAC774;

            &:before {
                width: 26px;
                height: 21px;
                background: url('../img/icon_listing@3x.svg'); } } }

    .box-visits {
        &:hover {
            border: 2px solid #A198F3; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #A198F3!important; }

        .box-links li:before {
            border-left: 4px solid #A198F3; }

        .box-img {
            background-color: #A198F3;

            &:before {
                width: 28px;
                height: 32px;
                background: url('../img/icon_plan navstev@3x.svg'); } } }

    .box-fotocampaign {
        &:hover {
            border: 2px solid #CB8C8C; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #CB8C8C!important; }

        .box-links li:before {
            border-left: 4px solid #CB8C8C; }

        .box-img {
            background-color: #CB8C8C;

            &:before {
                width: 30px;
                height: 30px;
                background: url('../img/icon_fotokampane@3x.svg'); } } }

    .box-actual {
        &:hover {
            border: 2px solid #97A2B4; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #97A2B4!important; }

        .box-links li:before {
            border-left: 4px solid #97A2B4; }

        .box-img {
            background-color: #97A2B4;

            &:before {
                width: 26px;
                height: 26px;
                background: url('../img/icon_obsazeni@3x.svg'); } } }

    .box-hlaseni {
        &:hover {
            border: 2px solid #71C5BB; }

        &:hover > .box-head span:nth-child(2) {
            border-color: #71C5BB!important; }

        .box-links li:before {
            border-left: 4px solid #71C5BB; }

        .box-img {
            background-color: #71C5BB;

            &:before {
                width: 25.5px;
                height: 29px;
                background: url('../img/icon_hlaseni@3x.svg'); } } } }
