@import "variables";

.foto-campaign {
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 100px;

    .foto-campaign-header {
        h2 {
            font-family: $roboto_;
            font-weight: bold;
            font-size: 35px;
            margin-bottom: 16px;

            span {
                font-weight: normal;
                font-family: $roboto_;
                font-size: 35px; } }

        h3 {
            font-size: 18px;
            font-weight: normal;
            margin-bottom: 40px;

            span {
                font-weight: bold; } } } }


.foto-campaign-table {
    display: inline-block;

    @media screen and ( max-width:  620px) {
        display: block!important; }


    .campaign-header {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 30px;
        span {
            margin-left: 10px; } }

    .dates-wrap {
        display: inline-block;
        @media screen and ( max-width:  620px) {
            width: 49%!important; } }

    .campaign-dates {
        margin-bottom: 20px;
        span {
            padding-right: 10px;
            font-size: 13px;
            font-weight: bold;

            &:first-child {
                margin-right: 35px; } }


        .date-to {
            margin-left: 20px;

            @media screen and ( max-width:  620px) {
                margin-left: 0!important; } }


        input {
            max-width: 120px;
            padding-right: 0;
            background-color: white;
            font-size: 13px;
            font-weight: bold;
            border: 1px solid $border-gray;
            height: 35px;
            border-radius: 3px;
            outline: none;
            padding-left: 5px;

            @media screen and ( max-width:  620px) {
                max-width: 100%!important;
                width: 100%!important;
                margin-top: 10px; }





            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                display: none; } } }

    table {
        text-align: left;
        @media screen and ( max-width:  620px) {
            width: 100%; }


        .line-hide {
            opacity: 0;
            visibility: hidden; }

        input, select {
            height: 35px;
            border-radius: 3px;
            border: 1px solid $border-gray;
            padding: 5px 10px;
            font-family: $roboto;
            font-size: 13px;
            font-weight: 500;
            outline: none;
            width: 200px;
            max-width: 200px;

            @media screen and ( max-width:  620px) {
                max-width: 100%!important;
                width: 100%!important; } }


        th, td span {
            font-weight: bold;
            font-size: 13px; }

        th, td {
            padding-bottom: 10px;

            @media screen and ( max-width:  620px) {
                display: block;
                padding-left: 0!important; } }

        th {
            padding-right: 15px;

            &:nth-of-type(even) {
                padding-left: 15px; } } }



    button {
        all: unset;
        border: none;
        cursor: pointer;
        background-color: $tyrkys;
        box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
        color: white;
        font-size: 15px;
        font-weight: medium;
        border-radius: 3px;
        padding: 9px 22px 9px 10px;
        float: right;
        margin-top: 10px;

        img {
            margin-right: 5px;
            vertical-align: middle;
            margin-top: -5px;
            height: 15px;
            width: 15px; }

        @media screen and ( max-width:  620px) {
            float: none;
            margin: 0 auto;
            margin-top: 10px;
            display: block;
            width: 50%;
            text-align: center; } } }

