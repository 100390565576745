@import "_variables";


.hlaseni {
    // margin-top: px
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 80px;

    .hlaseni-header {
        h2 {
            font-size: 35px;
            color: #000;
            font-family: $roboto_;
            margin-bottom: 20px; }

        .hlaseni-info {
            width: 100%;
            background-color: white;
            border-radius: 3px;
            border: 1px solid $border-gray;
            padding: 10px;
            margin-bottom: 50px;

            table {
                text-align: left;
                tr {
                    font-size: 14px;

                    th {
                        font-weight: 400; }
                    td {
                        font-weight: 500;
                        padding-left: 30px; } } } } } }

.hlaseni-table {
    margin-top: 40px;

    table {
        border-top: none; }

    table {
        tr {
            background-color: #efefef;

            &:nth-of-type(odd) {
                background-color: #ffffff; }


            &:first-child {
                background-color: transparent!important;
                border-bottom: 1px solid $border-gray;
                border-left: 1px solid #ECEFF0;
                border-right: 1px solid #ECEFF0; } } }


    th {
        font-size: 14px;
        padding-left: 2px;
        padding-bottom: 15px; }


    td {
        min-width: 100px;

        input {
            outline: none; }


        input[type="text"] {
            width: 1%;
            max-width: 100%;
            width: 100%;
            outline: none;
            border: 1px solid $border-gray;
            border-radius: 3px;
            height: 30px;
            padding-left: 5px;
            font-weight: 500;
            font-family: $roboto;
            font-size: 13px;
            margin-top: 5px; }

        select {
            height: 30px;
            padding: 5px;
            font-family: $roboto;
            font-weight: 500;
            font-size: 13px;
            border-radius: 3px;
            border: 1px solid $border-gray;
            outline: none;
            margin-top: 5px; }

        input[type="checkbox"] {
            border: 1px solid $border-gray;
            background-color: white; } } }

.hlaseni-note {
    margin-top: 60px;

    .hlaseni-note-info {
        margin-bottom: 20px;
        margin-top: 30px;
        display: inline-block;
        background-color: white;
        border: 1px solid $border-gray;
        border-radius: 3px;
        padding: 20px;
        font-size: 15px;
        line-height: 23px;
        width: 50%;

        @media screen and ( max-width: 1000px) {
            width: 70%!important; }

        @media screen and ( max-width: 700px) {
            width: 90%!important; }

        @media screen and ( max-width:  500px) {
            width: 100%!important; } } }



.hlaseni-files {
    margin-top: 80px;
    margin-bottom: 60px;

    .text-arrow-black {
        margin-bottom: 20px; } }

.file-upload-wrap {
    position: relative; }

.file-upload {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1; }

    .file-input {
        margin-top: 5px;
        padding: 10px;
        background: #fff;
        border: 1px solid $border-gray;
        border-radius: 3px;
        display: inline-block;
        min-height: 50px;
        width: 400px;
        max-width: 400px;
        vertical-align: middle;

        @media screen and ( max-width:  600px) {
            max-width: 250px;
            text-align: center; }

        .file-btn-input {
            background-color: $tyrkys;
            color: white;
            font-size: 13px;
            height: 40px;
            padding: 10px 15px;
            padding-left: 10px;
            box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
            border-radius: 3px;
            border: none;
            outline: none;
            display: inline-block;
            vertical-align: middle;
            line-height: 20px;

            @media screen and ( max-width:  600px) {
                padding: 5px 10px;
                margin-bottom: 10px; }



            img {
                margin-right: 10px;
                vertical-align: middle; } }

        span {
            font-size: 16px;
            margin-left: 20px;
            font-weight: 500;
            display: inline-block;
            vertical-align: middle;

            @media screen and ( max-width:  600px) {
                margin-left: 0;
                font-size: 13px;
                display: block; } } } }


.file-btn {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 15px; }

.other {
    margin-top: 40px;

    .text-arrow-black {
        margin-bottom: 50px; }

    .timeset {
        margin-bottom: 30px;
        @media screen and ( max-width:  620px) {
            text-align: center; } }


    .time-input {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 20px;
        text-align: left!important;

        @media screen and ( max-width:  620px) {
            margin-bottom: 40px; }
        &:last-child {
            margin-right: 0; }

        span {
            font-size: 13px;
            font-weight: 500;
            margin-right: 5px;

            @media screen and ( max-width:  620px) {
                display: block;
                margin-bottom: 5px; } }


        input {
            width: 100px;
            background-color: #fff;
            border: 1px solid $border-gray;
            height: 30px;
            padding: 10px 15px;
            font-family: $roboto;
            font-size: 13px;
            padding-left: 5px;
            font-weight: 500;
            outline: none;
            border-radius: 3px;

            @media screen and ( max-width:  620px) {
                width: 100%; } } }

    .input-wrap {
        position: relative;
        display: inline-block;

        p {
            position: absolute;
            font-size: 10px;
            bottom: calc(100% + 3px);
            left: 0;
            color: #000;
            white-space: nowrap;

            @media screen and ( max-width:  620px) {
                top: calc(100% + 5px); } } }


    .time-check {
        margin-left: 53px;
        text-align: left;

        @media screen and ( max-width:  620px) {
            margin-left: 0; }


        ul {
            li {
                display: inline-block;

                @media screen and ( max-width:  620px) {
                    width: 49%!important;
                    margin-bottom: 15px; }

                label {
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $roboto;
                    margin-right: 15px; }
                input {
                    margin-right: 5px;
                    vertical-align: middle; } } } } }

.hlaseni-btn {
    margin-top: 30px;
    height: 45px;
    background-color: #64D49F;
    box-shadow: 0 1px 0 0 #1A9D5F;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    border: none;
    padding: 5px 25px 5px 20px;
    line-height: 22px;
    cursor: pointer;

    @media screen and ( max-width:  620px) {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        padding: 5px 15px 5px 10px; }


    img {
        margin-right: 20px;
        vertical-align: middle;
        margin-top: -2px; } }
