@import "variables";



.all-documents {
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 100px;

    .all-documents-header {
        h1 {
            font-family: $roboto_;
            font-size: 35px;
            font-weight: bold;
            margin-bottom: 30px;

            @media screen and ( max-width:  620px) {
                font-size: 25px; } } }

    .all-documents-search {
        span {
            font-size: 13px;
            font-weight: bold;
            margin-right: 64px;

            @media screen and ( max-width:  620px) {
                display: block;
                margin-rigt: 0;
                margin-bottom: 10px; } }

        select {
            height: 36px;
            border-radius: 3px;
            border: 1px solid $border-gray;
            padding: 5px 10px;
            font-family: $roboto;
            font-size: 13px;
            font-weight: 500;
            outline: none;
            width: 298px;
            max-width: 298px;
            vertical-align: middle;
            margin-right: 16px;

            @media screen and ( max-width:  620px) {
                display: block;
                max-width: 100%!important;
                width: 100%!important;
                margin-bottom: 10px; } }

        button {
            all: unset;
            border: none;
            cursor: pointer;
            background-color: $tyrkys;
            box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
            color: white;
            font-size: 15px;
            font-weight: medium;
            border-radius: 3px;
            padding: 9px 22px 9px 10px;
            height: 35px;
            vertical-align: middle;
            margin-top: -1px;

            img {
                margin-right: 5px;
                vertical-align: middle;
                margin-top: -2px;
                height: 15px;
                width: 22px; }

            @media screen and ( max-width: 620px) {
                display: block;
                margin: 0 auto;
                float: none;
                margin-top: 20px;
                width: 50%;
                text-align: center; } } } }



.all-documents-table-wrap {
    overflow-x: auto; }

.all-documents-table {
    margin-top: 30px;
    max-width: 850px;

    h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px; }

    table {
        border-radius: 3px;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        min-width: 800px;

        th {
            font-size: 13px;
            font-weight: bold;
            padding-bottom: 12px;
            padding-left: 2px; }

        td {
            padding-left: 18px;
            padding: 20px;
            font-size: 13px;
            font-weight: 500;
            border-left: 1px solid $border-gray;
            border-right: 1px solid $border-gray; }

        tr {
            background-color: #efefef;
            border-left: 1px solid $border-gray;

            &:nth-of-type(odd) {
                background-color: white; }

            &:first-child {
                border-left: 1px solid #eceff0;
                border-right: 1px solid #eceff0;
                background-color: transparent;
                border-top: 1px solid #eceff0;
                border-bottom: 1px solid $border-gray; }

            &:last-child {
                border-bottom: 1px solid $border-gray; }

            td {
                &:first-child, &:last-child {
                    border: none; } } } } }


.outter-btn {
    padding: 0!important;
    background-color: #eceff0;
    border-bottom: 1px solid #eceff0!important;
    border-color: #eceff0!important;
    padding-left: 10px!important;

    .more-line-btn {
        width: 40px;
        height: 40px;
        background-color: $tyrkys;
        box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
        position: relative;
        display: inline-block;
        border: none;
        outline: none;
        border-radius: 4px;
        cursor: pointer;

        &:after {
            position: absolute;
            content: '';
            width: 22px;
            height: 15px;
            background: url('../img/icon_zobrazit.png');
            background-repeat: no-repeat;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } }

    .remove-line-btn {
        width: 40px;
        height: 40px;
        position: relative;
        display: inline-block;
        border: none;
        outline: none;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5px;

        &:after {
            position: absolute;
            content: '';
            width: 14px;
            height: 18px;
            background: url('../img/icon_delete.png');
            background-repeat: no-repeat;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); } } }

.add-document-btn {
    margin-top: 30px;
    height: 45px;
    background-color: #64D49F;
    box-shadow: 0 1px 0 0 #1A9D5F;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    border: none;
    padding: 5px 25px 5px 20px;
    line-height: 22px;
    cursor: pointer;


    img {
        margin-right: 20px;
        vertical-align: middle;
        margin-top: -2px; }

    @media screen and ( max-width: 620px) {
        display: block;
        margin: 0 auto;
        float: none;
        margin-top: 20px;
        width: 60%;
        text-align: center; } }
