@import "variables";
@import "section_boxes";
@import "section_merchandising";
@import "section_hlaseni";
@import "section_foto_campaign";
@import "section_all_documents";
@import "_checkbox";

select {
    background-color: white; }

button {
    outline: none; }


* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: $roboto;
    font-size: 16px;
    box-sizing: border-box!important; }
.clear {
    clear: both; }
body, html {
    height: 100%;
    width: 100%;
    background-color: #ECEFF0; }

header {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    box-shadow: 0 2px 10px rgba(18,23,37,0.12);
    padding: 20px;
    padding-bottom: 0;
    padding-left: 80px;
    z-index: 999;

    @media screen and ( max-width:  890px) {
        padding-left: 20px; }

    @media screen and ( max-width:  790px) {
        padding-bottom: 5px;
        height: 90px;
        overflow: hidden; }



    .header-logo img {
        padding-top: 10px; }

    .header-user {
        float: right;
        display: iniline-block;
        margin-right: 150px;
        padding-top: 10px;

        @media screen and ( max-width:  1100px) {
            margin-right: 20px; }

        @media screen and ( max-width:  790px) {
            float: none; }


        img {
            display: inline-block;
            vertical-align: top; }

        .user-info-more {
            display: inline-block;
            margin-left: 5px;

            span {
                display: block;
                font-weight: bold;

                &:last-child {
                    margin-top: 3px;
                    font-size: 13px;
                    font-weight: normal; } } }

        .header-user-btn-wrap {
            float: right;
            display: inline-block;
            veritcal-align: top;
            margin-left: 40px;

            @media screen and ( max-width:  790px) {
                float: none;
                margin-left: 0;
                margin-top: 20px;
                margin-bottom: 40px;
                display: block; } }

        .header-user-btn {
            color: $pink;
            border: 1px solid $pink;
            font-weight: 500;
            border-radius: 50px;
            padding: 8px 15px;
            margin-top: -15px;


            img {
                margin-right: 5px; } } }




    .header-links {
        margin-top: 25px;
        margin-bottom: 20px;
        ul {
            li {
                display: inline-block;
                margin-bottom: 20px;

                @media screen and ( max-width: 790px ) {
                    display: block; }


                a {
                    font-weight: bold;
                    color: #000;
                    margin-right: 20px;
                    position: relative;

                    @media screen and ( max-width: 790px ) {
                        padding: 10px 15px;
                        padding-left: 0; }

                    &:after {
                        position: absolute;
                        left: calc(100% + 10px);
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        border-left: 4px solid #C3C9D0;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);

                        @media screen and ( max-width: 790px ) {
                            left: 20px;
                            top: calc(100%);
                            border: none;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 4px solid #C3C9D0; } } } } } } }


.main-content {
    margin-top: 200px;

    @media screen and ( max-width: 1240px) {
        padding-left: 20px!important;
        padding-right: 20px!important; } }


.text-arrow-black {

    span {
        color: white;
        background-color: #000;
        padding: 10px 40px 10px  15px;
        position: relative;
        height: 40px;
        font-family: $roboto_;
        font-weight: bold;
        font-size: 20px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            width: 0;
            height: 0;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            border-left: 22px solid #000; } } }


footer {
    margin-top: 80px;
    text-align: center;
    padding: 20px;
    padding-top: 40px;
    background-color: #3E3E3E;
    min-height: 130px;

    span {
        font-size: 13px;
        display: block;
        margin-bottom: 20px;
        color: #B2B9C6; }

    img {
        max-width: 140px; } }


.mobile-menu {
    position: absolute;
    top: 35px;
    right: 50px;
    cursor: pointer;
    display: none;

    @media screen and ( max-width:  790px) {
        display: block; }


    span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: lighten(#747881, 10%);
        display: inline-block;
        margin-right: 2px; } }

.mobile-header {
    height: auto!important;
    max-height: 100%!important;
    overflow-y: auto; }
