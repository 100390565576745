@import "variables";

.merchandising-header {
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 80px;

    @media screen and ( max-width:  860px) {
        margin-bottom: 10px!important; }

    h1 {
        font-family: $roboto_;
        font-weight: bold;
        font-size: 35px;
        display: inline-block;

        @media screen and ( max-width:  860px) {
            font-size: 20px; }


        span {
            font-weight: normal;
            font-size: 35px;

            @media screen and ( max-width:  860px) {
                font-size: 20px; }

            @media screen and ( max-width:  560px) {
                display: block;
                font-size: 18px; } } }


    button {
        all: unset;
        border: none;
        cursor: pointer;
        float: right;
        background-color: $tyrkys;
        box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
        color: white;
        font-size: 15px;
        font-weight: medium;
        border-radius: 3px;
        padding: 9px 22px 9px 10px;

        @media screen and ( max-width:  560px) {
            padding: 10px; }


        img {
            margin-right: 5px;
            vertical-align: middle;
            margin-top: -5px;
            height: 15px;
            width: 15px; }

        span {
            @media screen and ( max-width:  560px) {
                display: none; } } } }



.merchandising-table {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 1240px;

    .table-inputs {
        font-size: 0;
        margin-bottom: 20px;
        padding-left: 25px;

        @media screen and ( max-width:  860px) {
            padding-top: 15px!important;
            padding-left: 0!important; }


        .input {
            display: inline-block;
            padding-right: 1px;
            padding-left: 1px;

            @media screen and ( max-width: 1080px ) {
                width: 200px!important; }

            @media screen and ( max-width: 1080px ) {
                 margin-bottom: 5px!important; }

            @media screen and ( max-width: 882px ) {
                width: 180px!important; }

            @media screen and ( max-width: 620px ) {
                width: 50%!important; }

            @media screen and ( max-width:  500px ) {
                width: 100%!important;
                margin-bottom: 10px!important; }

            &:first-child {
                padding-left: 0;
                padding-rigt: 2px!important; }
            &:last-child {
                padding-right: 0;
                padding-left: 2px!important; }

            input {
                width: 100%;
                outline: none;
                padding-left: 15px;
                height: 35px;
                line-height: 35px;
                font-family: $roboto;
                font-weight: 500;
                font-size: 13px;
                border-radius: 3px;
                border: 1px solid #C3C9D0; }


            input[type="number"] {
                padding-left: 5px; }

            span {
                display: block;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
                padding-left: 2px; } } } }



.table-wrap {
    max-width: 1240px;
    overflow-x: auto;
    padding-left: 25px;


    table {
        min-width: 1000px;
        width: 100%;
        text-align: left;
        border: 1px solid #C3C9D0;
        border-collapse: collapse;

        tr {
            background-color: #efefef;
            &:nth-of-type(odd) {
                background-color: #ffffff; } }

        tr:nth-child(2) {
            border-top: 1px solid $border-gray; }

        td {
            position: relative;
            padding: 15px;
            padding-top: 5px;
            max-width: 200px;
            border-right: 1px solid #C3C9D0;
            font-size: 13px;
            font-weight: 500;

            &:last-child {
                border-right: none; } } }

    .line-edit-btn {
        position: absolute;
        cursor: pointer;
        right: calc(100% + 5px);
        top: 50%;
        transform: translateY(-50%);
        z-index: 900;
        width: 18px;
        height: 16.5px;
        background: url('../img/icon_edit.png');
        background-repeat: no-repeat; } }

.mobile-pagination {
    display: none;

    button {
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        @media screen and ( max-width:  860px) {
            width: 40%; } }
    @media screen and ( max-width:  860px) {
        display: block; } }


.pagination {
    margin-top: 40px;
    float: right;

    @media screen and ( max-width:  860px) {
        display: none; }

    .fl {
        border: 1px solid #C3C9D0;
        border-radius: 3px;
        padding: 5px 10px;
        font-size: 13px;
        color: #000;
        background-color: white;
        cursor: pointer;
        height: 30px;
        outline: none;

        &:hover {
            background-color: $tyrkys;
            color: white;
            border-color: $tyrkys; } }

    .pagination-number {
        &:hover {
            cursor: pointer;
            background-color: $tyrkys;
            color: white;
            border-color: $tyrkys; } }

    .number-dots:hover {
        background-color: white;
        color: #000;
        cursor: default;
        border-color: #c3c9d0; }

    .pagination-numbers {
        display: inline-block;
        vertical-align: bottom;

        .pagination-arrow {
            width: 30px;
            height: 30px;
            position: relative;
            cursor: pointer;

            &:before {
                width: 0;
                height: 0;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent; } }

        .arrow-l {
            border-radius: 3px 0 0 3px;
            &:before {
                border-right: 5px solid $tyrkys; } }
        .arrow-r {
            border-right: 1px solid #c3c9d0;
            border-radius: 0 3px 3px 0;

            &:before {
                border-left: 5px solid $tyrkys; } }


        ul {
            font-size: 0;
            border-radius: 3px;
            margin-left: 5px;
            margin-right: 5px;
            li {
                display: inline-block;
                vertical-align: bottom;

                button {
                    height: 30px;
                    width: 30px;
                    border: none;
                    background-color: #fff;
                    outline: none;
                    padding: 5px;
                    font-family: $roboto;
                    font-size: 13px;
                    border-bottom: 1px solid #c3c9d0;
                    border-top: 1px solid #c3c9d0;
                    border-left: 1px solid #C3C9D0; } } } } }

.table-mobile-head {
    opacity: 0;

    th {
        font-size: 14px;
        font-weight: bold;
        padding-left: 2px;
        padding-bottom: 10px;
        background-color: #ECEFF0;
        border: 1px solid #ECEFF0;
        border-bottom: none; }

    @media screen and ( max-width:  1080px) {
        opacity: 1; } }

.merchandising-btn-wrap {
    display: none;

    @media screen and ( max-width:  860px) {
        display: block; } }

.merchandising-btn-mobile {
    all: unset;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    background-color: $tyrkys;
    box-shadow: 0 1px 0 0 rgba(38,101,146,0.62);
    color: white;
    font-size: 15px;
    font-weight: medium;
    border-radius: 3px;
    padding: 9px 22px 9px 10px;
    margin-top: 20px;
    display: block;


    img {
        margin-right: 5px;
        vertical-align: middle;
        margin-top: -5px;
        height: 15px;
        width: 15px; } }

