$width: 20px;
$height: 20px;

@keyframes click-wave {
  0% {
    height: $height;
    width: $width;
    opacity: 0.35;
    position: relative; }

  100% {
    height: 40px;
    width: 40px;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0; } }

.inline-checkbox {
    top: 0!important; }

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 5px;
  left: 0;
  height: $height;
  width: $width;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  z-index: 900;

  &:hover {
    background: #9faab7!important; }

  &:checked {
    background: $tyrkys!important;

    &::before {
      height: $height;
      width: $width;
      position: absolute;
      content: '✔';
      display: inline-block;
      font-size: 10px;
      text-align: center;
      line-height: $width;
      color: white!important; }

    &::after {
      -webkit-animation: click-wave 0.65s;
      -moz-animation: click-wave 0.65s;
      animation: click-wave 0.65s;
      background: $tyrkys;
      content: '';
      display: block;
      position: relative;
      z-index: 100; } } }
